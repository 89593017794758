/* eslint-disable max-len */
import React from 'react';
import uniqid from 'uniqid';

export const IconMessage: React.FC = () => (
  <svg
    height="16px"
    version="1.1"
    viewBox="0 0 20 16"
    width="20px"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
  >
    <g fill="none" fillRule="evenodd" id={uniqid('group-')} stroke="none" strokeWidth="1">
      <g fill="currentColor" id={uniqid('group-')} transform="translate(-144.000000, -1183.000000)">
        <g id={uniqid('group-')} transform="translate(18.000000, 1179.000000)">
          <path
            d="M136,17.7038034 C141.522847,17.7038034 146,14.6361025 146,10.8519017 C146,7.06770088 141.522847,4 136,4 C130.477153,4 126,7.06770088 126,10.8519017 C126,13.2548199 127.805226,15.3688393 130.537114,16.5919769 C131.28654,16.9275145 131.647505,18.5204429 130.537114,19.9877706 C131.490989,20.1948381 134.94613,17.7038034 136,17.7038034 Z"
            id={uniqid('path-')}
          />
        </g>
      </g>
    </g>
  </svg>
);
