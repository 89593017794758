import styled from '@colligent/colligent-component-framework';

export const ContentContainer = styled.div<{}>(({ theme }) => ({
  padding: '0 15px',
  width: 'calc(100% - 30px)',
  maxWidth: 1600,

  [theme.breakpoints.mobileAndLower]: {
    padding: 0,
    width: '100%'
  }
}));
