/* eslint-disable max-len */
import React from 'react';
import uniqid from 'uniqid';

export interface IIconArrow {
  color: string;
}

export const IconArrow: React.FC<IIconArrow> = ({ color }) => {
  return (
    <svg height="17px" version="1.1" viewBox="0 0 10 17" width="10px" xmlns="http://www.w3.org/2000/svg">
      <g fill="none" fillRule="evenodd" id={uniqid('group-')} stroke="none" strokeWidth="1">
        <g fill={color} id={uniqid('group-')} transform="translate(-223.000000, -1252.000000)">
          <g id={uniqid('group-')} transform="translate(71.000000, 1230.000000)">
            <path
              d="M161.56376,29.4083694 C161.863561,29.7090435 162.008836,30.1060074 161.999585,30.5 C162.008836,30.8939926 161.863561,31.2909565 161.56376,31.5916306 C161.501217,31.6543563 159.160827,33.9779758 154.542591,38.5624892 C153.960938,39.1458369 153.017892,39.1458369 152.43624,38.5624892 C151.854587,37.9791414 151.854587,37.0333478 152.43624,36.45 L158.368951,30.5 L152.43624,24.55 C151.854587,23.9666522 151.854587,23.0208586 152.43624,22.4375108 C153.017892,21.8541631 153.960938,21.8541631 154.542591,22.4375108 C159.160827,27.0220242 161.501217,29.3456437 161.56376,29.4083694 Z"
              id={uniqid('path-')}
            />
          </g>
        </g>
      </g>
    </svg>
  );
};
