/* eslint-disable max-len */
import styled from '@colligent/colligent-component-framework';
import { useTranslation } from '@colligent/use-translation';
import React, { useContext } from 'react';
import { AppConfigContext } from '../config';
import { FooterMenu } from './FooterMenu';
import { resources } from './resources.i18n';
import ropoLogo from '../logos/Ropo_logo4_RGB_white_103x40.png';

const Footer = styled.footer<{}>(({ theme }) => ({
  color: theme.colors.white,
  display: 'flex',
  flexDirection: 'column',
  font: theme.fonts.desktop.small,
  backgroundColor: theme.colors.deepBlue,
  height: 'auto',
  position: 'absolute',
  bottom: 0,
  width: '100%',

  [theme.breakpoints.mobileAndLower]: {
    height: 'auto',
    position: 'static',
    bottom: 'unset'
  },

  '& a': {
    color: theme.colors.white
  }
}));

const FooterContent = styled.section<{}>(({ theme }) => ({
  flex: 'auto',
  display: 'flex',
  flexDirection: 'row',

  [theme.breakpoints.mobileAndLower]: {
    flexDirection: 'column'
  }
}));

const Copyright = styled.div<{}>(({ theme }) => ({
  flex: 'auto',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  backgroundColor: theme.colors.deepBlue,
  color: theme.colors.white,
  fontSize: 12,
  minHeight: 30,
  lineHeight: 1,
  width: '100%'
}));

const RopoLogo: React.FC<{ className?: string }> = ({ className }) => {
  return <img alt="Ropo" className={className} height={30} src={ropoLogo} width={74} />;
};

const Logo = styled(RopoLogo)({
  marginLeft: 20
});

const LegalNameAndAddress = styled.address<{}>(({ theme }) => ({
  color: theme.colors.white,
  flex: 1,
  fontStyle: 'normal',
  margin: '15px 30px 0 20px',
  minWidth: 100,

  [theme.breakpoints.mobileAndLower]: {
    minWidth: '70%'
  },

  '& > strong': {
    display: 'block'
  }
}));

const PhoneAndEmail = styled.aside<{}>(({ theme }) => ({
  flex: 1,
  margin: '15px 30px 0 20px',
  font: theme.fonts.desktop.small,
  minWidth: 150,

  [theme.breakpoints.mobileAndLower]: {
    minWidth: 'unset',
    margin: '15px 20px 0 20px'
  },

  '& > strong': {
    display: 'block'
  },

  '& a': {
    textDecoration: 'none'
  }
}));

const OrganizationInfo = styled.section<{}>(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  maxWidth: '460px',
  minWidth: '130px',
  margin: '20px 0',
  paddingRight: 30,

  [theme.breakpoints.mobileAndLower]: {
    margin: '20px 0 0 0',
    paddingRight: 0
  },

  '& > h1': {
    margin: 0
  },

  '& > div ': {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap'
  }
}));

const NoWrap = styled.span<{}>(() => ({
  whiteSpace: 'nowrap'
}));

interface ILegalName {
  legalName?: string;
}

interface IOrganizationNumber {
  organizationNumber?: string;
}

interface IPhoneNumber {
  phoneNumber: string;
}

interface IEmailAddress {
  emailAddress: string;
}

interface IAddress {
  street1: string;
  street2?: string;
  postalCode: string;
  city: string;
  country?: string;
}

interface IOrganizationInfo {
  legalName?: string;
  organizationNumber?: string;
  address?: IAddress;
  phone?: string;
  email?: string;
}

interface IPostalAddress {
  address?: IAddress;
}

const LegalName: React.FC<ILegalName> = ({ legalName }) => {
  return legalName ? (
    <NoWrap>
      <strong>{legalName}</strong>
    </NoWrap>
  ) : null;
};

const OrganizationNumber: React.FC<IOrganizationNumber> = ({ organizationNumber }) => {
  const t = useTranslation(resources);

  return organizationNumber ? (
    <>
      <NoWrap>
        <strong>{`${t('OrganizationNumber')}:`}</strong>
      </NoWrap>
      <br />
      <NoWrap>{organizationNumber}</NoWrap>
      <br />
      <br />
    </>
  ) : null;
};

const PhoneNumber: React.FC<IPhoneNumber> = ({ phoneNumber }) => {
  const t = useTranslation(resources);

  return phoneNumber ? (
    <>
      <strong>{`${t('PhoneNumber')}:`}</strong>
      <a href={`tel:${phoneNumber}`}>{phoneNumber}</a>
      <br />
      <br />
    </>
  ) : null;
};

const EmailAddress: React.FC<IEmailAddress> = ({ emailAddress }) => {
  const t = useTranslation(resources);

  return emailAddress ? (
    <>
      <strong>{`${t('Email')}:`}</strong>
      <a href={`mailto:${emailAddress}`}>{emailAddress}</a>
      <br />
      <br />
    </>
  ) : null;
};

const PostalAddress: React.FC<IPostalAddress> = ({ address }) => {
  return address ? (
    <>
      <strong />
      <br />
      <NoWrap>{address.street1}</NoWrap>
      <br />
      {address.street2 && (
        <>
          <NoWrap>{address.street2}</NoWrap>
          <br />
        </>
      )}
      <NoWrap>{`${address.postalCode} ${address.city}`}</NoWrap>
      {address.country && (
        <>
          <br />
          <NoWrap>{address.country}</NoWrap>
        </>
      )}
    </>
  ) : null;
};

const getCurrentYear = () => new Date().getFullYear();

export const DebtorAppFooter: React.FC = () => {
  const { organizationInfo } = useContext(AppConfigContext);

  return (
    <Footer>
      <FooterContent>
        <OrganizationInfo>
          <h1>
            <Logo />
          </h1>
          {organizationInfo ? (
            <div>
              <LegalNameAndAddress>
                <LegalName legalName={organizationInfo.legalName} />
                <PostalAddress address={organizationInfo.address} />
              </LegalNameAndAddress>
              <PhoneAndEmail>
                <OrganizationNumber organizationNumber={organizationInfo.organizationNumber} />
                {organizationInfo.phone && <PhoneNumber phoneNumber={organizationInfo.phone} />}
                {organizationInfo.email && <EmailAddress emailAddress={organizationInfo.email} />}
              </PhoneAndEmail>
            </div>
          ) : null}
        </OrganizationInfo>
        <FooterMenu />
      </FooterContent>
      <Copyright>{`Copyright 2015 - ${getCurrentYear()} Ropo`}</Copyright>
    </Footer>
  );
};
