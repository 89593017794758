import React from 'react';

interface IFlag {
  locale: string;
}

export const Flag: React.FC<IFlag> = ({ locale }) => {
  switch (locale) {
    case 'sv':
      return <SvFlag />;
    case 'fi':
      return <FiFlag />;
    default:
      return <EnFlag />;
  }
};

const SvFlag = () => (
  <svg
    width='24px'
    height='8px'
    viewBox='0 0 24 8'
    version='1.1'
    xmlns='http://www.w3.org/2000/svg'
    xmlnsXlink='http://www.w3.org/1999/xlink'
  >
    <g id='1_1_Start' stroke='none' strokeWidth='1' fill='none' fillRule='evenodd'>
      <g id='New-beter-menu' transform='translate(-185.000000, -233.000000)'>
        <g id='Group-2' transform='translate(0.000000, 189.000000)'>
          <g id='Group-11-Copy-2' transform='translate(185.000000, 44.000000)'>
            <rect id='Rectangle-Copy-17' fill='#006AA7' x='0' y='0' width='24' height='8' rx='4' />
            <rect id='Rectangle-Copy-18' fill='#FECC00' x='4.75' y='0' width='8.1' height='8' />
          </g>
        </g>
      </g>
    </g>
  </svg>
);

const FiFlag = () => (
  <svg
    width='24px'
    height='8px'
    viewBox='0 0 24 8'
    version='1.1'
    xmlns='http://www.w3.org/2000/svg'
    xmlnsXlink='http://www.w3.org/1999/xlink'
  >
    <g id='1_1_Start' stroke='none' strokeWidth='1' fill='none' fillRule='evenodd'>
      <g id='New-beter-menu' transform='translate(-185.000000, -361.000000)'>
        <g id='Group-2' transform='translate(0.000000, 189.000000)'>
          <g id='Group-13-Copy-2' transform='translate(185.000000, 172.000000)'>
            <rect id='Rectangle' fill='#FFFFFF' x='0' y='0' width='24' height='8' rx='4' />
            <rect id='Rectangle' fill='#002F6C' x='4.75' y='0' width='8.1' height='8' />
          </g>
        </g>
      </g>
    </g>
  </svg>
);

export const EnFlag = () => (
  <svg
    width='24px'
    height='8px'
    viewBox='0 0 24 8'
    version='1.1'
    xmlns='http://www.w3.org/2000/svg'
    xmlnsXlink='http://www.w3.org/1999/xlink'
  >
    <defs>
      <rect id='path-1' x='0' y='0' width='24' height='8' rx='4' />
    </defs>
    <g id='1_1_Start' stroke='none' strokeWidth='1' fill='none' fillRule='evenodd'>
      <g id='New-beter-menu' transform='translate(-185.000000, -425.000000)'>
        <g id='Group-2' transform='translate(0.000000, 189.000000)'>
          <g id='Group-4-Copy-3' transform='translate(185.000000, 236.000000)'>
            <mask id='mask-2' fill='white'>
              <use xlinkHref='#path-1' />
            </mask>
            <use id='Rectangle' fill='#001F7E' xlinkHref='#path-1' />
            <polygon id='Rectangle' fill='#D00C27' mask='url(#mask-2)' points='9 0 15 0 11 8 5 8' />
            <polygon
              id='Rectangle'
              fill='#FFFFFF'
              mask='url(#mask-2)'
              points='15 0 18 0 14 8 11 8'
            />
            <polygon id='Rectangle' fill='#FFFFFF' mask='url(#mask-2)' points='7 0 9 0 5 8 3 8' />
          </g>
        </g>
      </g>
    </g>
  </svg>
);
