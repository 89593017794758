import styled from '@colligent/colligent-component-framework';
import { Spinner } from '@colligent/colligent-component-framework/dist/common/components/Spinner';
import React from 'react';

const MainSpinnerContainer = styled('div')<{}>(() => ({
  height: '100vh'
}));

export const MainSpinner: React.FC = () => {
  return (
    <MainSpinnerContainer>
      <Spinner centered={true} spinnerType="large" style={{ top: '30vh' }} />
    </MainSpinnerContainer>
  );
};
