export enum PopupAction {
  SetIsOpen = 'SET_IS_OPEN',
  SetIsError = 'SET_IS_ERROR',
  SetPopupTitle = 'SET_POPUP_TITLE',
  SetPopupMessage = 'SET_POPUP_MESSAGE',
  SetButtonText = 'SET_BUTTON_TEXT'
}

interface IPopupState {
  isOpen: boolean;
  isError: boolean;
  title: string;
  message: string;
  buttonText: string;
}

export interface IPopupAction {
  type: PopupAction;
  payload?: string | boolean;
  callback?: () => {} | void;
}

export const initialPopupState: IPopupState = {
  isOpen: false,
  isError: false,
  title: 'title',
  message: 'message',
  buttonText: 'Ok'
};

export const popupReducer = (state: IPopupState, action: IPopupAction): IPopupState => {
  switch (action.type) {
    case 'SET_IS_OPEN':
      return { ...state, isOpen: action.payload as boolean };
    case 'SET_IS_ERROR':
      return { ...state, isError: action.payload as boolean };
    case 'SET_POPUP_TITLE':
      return { ...state, title: action.payload as string };
    case 'SET_POPUP_MESSAGE':
      return { ...state, message: action.payload as string };
    case 'SET_BUTTON_TEXT':
      return { ...state, buttonText: action.payload as string };
    default:
      return state;
  }
};
