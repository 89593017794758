/* eslint-disable max-len */
import styled from '@colligent/colligent-component-framework';
import { Button } from '@colligent/colligent-component-framework/dist/common/components/Button';
import { Cover } from '@colligent/colligent-component-framework/dist/components/Cover';
import { createEncodedBgSvg } from '@colligent/colligent-component-framework/dist/formatters';
import { hasClassInTree } from '@colligent/colligent-component-framework/dist/helpers/dom-helpers';
import { LocaleContext } from '@colligent/use-translation';
import { lighten } from 'polished';
import React, { useContext, useEffect, useState } from 'react';
import uniqid from 'uniqid';
import { Flag } from './LangFlagIcons';

const TOPBAR_LANG_CLASS = 'topbar-lang-menu';

const activeCheckMarkIcon = createEncodedBgSvg(`<svg width="22px" height="22px" viewBox="0 0 22 22" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
<g id="${uniqid('group-')}" stroke="none" stroke-width="2" fill="none" fill-rule="evenodd">
    <g id="${uniqid('group-')}" transform="translate(-92.000000, -290.000000)">
        <g id="${uniqid('group-')}" transform="translate(0.000000, 189.000000)">
            <g id="${uniqid('group-')}" transform="translate(93.000000, 102.000000)">
                <path d="M0,10 C0,15.5333333 4.46666667,20 10,20 C15.5333333,20 20,15.5333333 20,10 C20,4.46666667 15.5333333,0 10,0 C4.46666667,0 0,4.46666667 0,10 Z" id="${uniqid(
                  'path-'
                )}" stroke="#FFFFFF"></path>
                <polygon id="${uniqid(
                  'polygon-'
                )}" fill="#FFFFFF" points="6.25870015 13.284233 4 10.9659903 5.64415767 9.27849026 7.90285781 11.596733 13.3558423 6 15 7.6875 7.87531678 15 6.23115912 13.3125"></polygon>
            </g>
        </g>
    </g>
</g>
</svg>`);

const LangContainer = styled.div<{ isOpen: boolean }>(({ theme, isOpen }) => ({
  alignItems: 'center',
  backgroundColor: isOpen ? lighten(0.05, theme.colors.deepBlue) : theme.colors.deepBlue,
  color: theme.colors.white,
  display: 'inline-flex',
  flexBasis: 'min-content',
  flexFlow: 'row',
  marginLeft: 'auto',
  fontFamily: theme.fontStack.text,
  height: 44,
  justifyContent: 'center',
  minWidth: 55,

  ':hover': {
    cursor: 'pointer'
  }
}));

const InnerContainer = styled.div<{}>(({ theme }) => ({
  display: 'inline-flex',
  flexDirection: 'column',
  font: theme.fonts.mobile.medium,
  textAlign: 'center',
  justifyContent: 'center',
  height: '100%',
  padding: '0 15px 0 15px',
  zIndex: 100,
  backgroundColor: lighten(0.05, theme.colors.deepBlue)
}));

const ActiveLocale = styled('span')<{}>(({ theme }) => ({
  fontFamily: theme.fontStack.textHeavy,
  textTransform: 'capitalize'
}));

const List = styled.ul<{ isOpen: boolean }>(({ theme, isOpen }) => ({
  display: 'flex',
  listStyle: 'none',
  position: 'absolute',
  width: 350,
  padding: 0,
  backgroundColor: lighten(0.05, theme.colors.deepBlue),
  zIndex: 101,
  flexDirection: 'column',
  margin: 0,
  maxHeight: isOpen ? 500 : 0,
  font: theme.fonts.desktop.large,
  overflow: 'hidden',
  transition: 'max-height .35s cubic-bezier(.8,.2,.2,.8)',
  right: 0,
  top: 44,

  '&>li:last-of-type': {
    marginBottom: 30
  },

  [theme.breakpoints.mobileAndLower]: {
    width: '100%'
  }
}));

const ListItem = styled.li<{}>(({ theme }) => ({
  font: theme.fonts.desktop.large,
  position: 'relative',
  display: 'flex',
  flexDirection: 'column',
  margin: '30px auto 0 auto',
  minWidth: 170,

  '&.active': {
    backgroundImage: activeCheckMarkIcon,
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',
    backgroundPositionX: 15,
    backgroundPositionY: 8,
    backgroundSize: 25
  },

  '& > button:hover': {
    backgroundColor: 'transparent'
  }
}));

const MenuLangIcon = styled.span<{}>(() => ({
  minWidth: 20,
  minHeight: 10,
  position: 'absolute',
  right: 15,
  top: 10
}));

interface ILocaleProps {
  label: string;
  code: string;
}

interface IAvailableLocales {
  [key: string]: ILocaleProps;
}

export interface ILangMenuProps {
  readonly menuItems?: IAvailableLocales;
}

let isMenuOpen = false;

const MenuItem: React.FC<ILocaleProps> = ({ label, code }) => {
  const { activeLocale, changeLocale } = useContext(LocaleContext);

  const handleOnClick = () => {
    isMenuOpen = true;

    if (changeLocale) {
      changeLocale(code);
    }
  };

  return (
    <ListItem className={activeLocale === code ? 'active' : ''}>
      <Button buttonType="lang" id={`menu-language-button-${code}`} onClick={handleOnClick}>
        {label}
        <MenuLangIcon>
          <Flag locale={code} />
        </MenuLangIcon>
      </Button>
    </ListItem>
  );
};

export const MenuLanguages: React.FC<ILangMenuProps> = ({ menuItems }) => {
  const [isOpen, setIsOpen] = useState(false);
  const { activeLocale } = useContext(LocaleContext);

  useEffect(() => {
    const pageClick = (e: any) => {
      const LangIsClicked = hasClassInTree(e.target, TOPBAR_LANG_CLASS);
      const openTheMenu = LangIsClicked && !isOpen;
      setIsOpen(openTheMenu);
    };

    document.addEventListener('click', pageClick, false);

    return function cleanup() {
      if (isMenuOpen) {
        isMenuOpen = false;
        setIsOpen(isMenuOpen);
      }
      document.removeEventListener('click', pageClick, false);
    };
  });

  const handleOnClick = () => {
    setIsOpen(!isOpen);
  };

  return (
    <LangContainer isOpen={isOpen}>
      <InnerContainer className={TOPBAR_LANG_CLASS} onClick={handleOnClick}>
        <ActiveLocale>{activeLocale}</ActiveLocale>
        <Flag locale={activeLocale} />
      </InnerContainer>
      <List isOpen={isOpen}>
        {menuItems &&
          Object.keys(menuItems).map(item => (
            <MenuItem key={uniqid()} code={menuItems[item].code} label={menuItems[item].label} />
          ))}
      </List>
      <Cover isCoverOpen={isOpen} />
    </LangContainer>
  );
};
