/* eslint-disable max-len */
import React from 'react';
import uniqid from 'uniqid';

export const IconLock: React.FC<{
  className?: string;
}> = ({ className }) => (
  <svg
    className={className}
    height="20px"
    version="1.1"
    viewBox="0 0 14 20"
    width="14px"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
  >
    <g fill="none" fillRule="evenodd" id={uniqid('group-')} stroke="none" strokeWidth="1">
      <g fill="#333333" id={uniqid('group-')} transform="translate(-456.000000, -418.000000)">
        <g id={uniqid('group-')} transform="translate(456.000000, 418.000000)">
          <path
            d="M10.2654867,7.87610619 L10.2654867,5.22891457 C10.2654867,3.36706339 8.75952359,1.85840708 6.90265487,1.85840708 C5.04578614,1.85840708 3.53982301,3.36706339 3.53982301,5.22891457 L3.53982301,7.87610619 L10.2654867,7.87610619 Z M1.68141593,7.89405641 L1.68141593,5.22891457 C1.68141593,2.34143609 4.0186722,-2.49578136e-13 6.90265487,-2.49578136e-13 C9.78663753,-2.49578136e-13 12.1238938,2.34143609 12.1238938,5.22891457 L12.1238938,7.90820818 C13.0301303,8.0746706 13.7168142,8.86864792 13.7168142,9.82300885 L13.7168142,18.0530973 C13.7168142,19.128342 12.8451562,20 11.7699115,20 L1.94690265,20 C0.871658009,20 -6.03961325e-14,19.128342 -6.03961325e-14,18.0530973 L-6.03961325e-14,9.82300885 C-6.03961325e-14,8.83781866 0.731765119,8.02354137 1.68141593,7.89405641 Z M1.68141593,9.6460177 L1.68141593,18.3185841 L11.9469027,18.3185841 L11.9469027,9.6460177 L1.68141593,9.6460177 Z"
            fillRule="nonzero"
            id={uniqid('path-')}
          />
          <path
            d="M5.92920354,14.527307 C5.45112907,14.230814 5.13274336,13.701261 5.13274336,13.0973451 C5.13274336,12.1687248 5.88553892,11.4159292 6.81415929,11.4159292 C7.74277967,11.4159292 8.49557522,12.1687248 8.49557522,13.0973451 C8.49557522,13.701261 8.17718951,14.230814 7.69911504,14.527307 L7.69911504,15.9292035 L7.65860157,15.9292035 C7.54583487,16.2882396 7.21041078,16.5486726 6.81415929,16.5486726 C6.41790781,16.5486726 6.08248371,16.2882396 5.96971702,15.9292035 L5.92920354,15.9292035 L5.92920354,14.527307 Z"
            id={uniqid('path-')}
          />
        </g>
      </g>
    </g>
  </svg>
);
