import { ILocaleResource } from '@colligent/use-translation';

export const resources: ILocaleResource = {
  en: {
    Cases: 'Cases',
    'Logged in as': 'Logged in as',
    Messages: 'Messages',
    'My Details': 'My Details',
    'My Pages': 'My Pages',
    Logout: 'Log out',
    MenuOpenLabel: 'Menu',
    MenuClosedLabel: 'Close',
    ProfileMobileLabel: 'User',
    PhoneNumber: 'Phone',
    Email: 'Email',
    OrganizationNumber: 'Organization number',
    FAQ: 'FAQ',
    Complaints: 'Complaints',
    PersonalData: 'Processing of personal data',
    Cookies: 'Cookies'
  },
  fi: {
    Cases: 'Perintäasiat',
    'Logged in as': 'Kirjautuneena sisään',
    Messages: 'Viestit',
    'My Details': 'Omat tiedot',
    'My Pages': 'Oma Ropo',
    Logout: 'Kirjaudu ulos',
    MenuOpenLabel: 'Valikko',
    MenuClosedLabel: 'Sulje',
    ProfileMobileLabel: 'Käyttäjä',
    PhoneNumber: 'Puhelin',
    Email: 'Sähköposti',
    OrganizationNumber: 'Y-tunnus',
    FAQ: 'Usein kysyttyä',
    Complaints: 'Reklamaatiot',
    PersonalData: 'Henkilötietojen käsittely',
    Cookies: 'Evästeet'
  },
  sv: {
    Cases: 'Ärenden',
    'Logged in as': 'Inloggad som',
    Messages: 'Meddelanden',
    'My Details': 'Mina uppgifter',
    'My Pages': 'Mina sidor',
    Logout: 'Logga ut',
    MenuOpenLabel: 'Meny',
    MenuClosedLabel: 'Stäng',
    ProfileMobileLabel: 'Användare',
    PhoneNumber: 'Telefon',
    Email: 'E-post',
    OrganizationNumber: 'Organisationsnummer',
    FAQ: 'Vanliga frågor',
    Complaints: 'Klagomål',
    PersonalData: 'Dataskydd',
    Cookies: 'Om cookies'
  }
};
