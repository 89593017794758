import styled from '@colligent/colligent-component-framework';

export const ContentText = styled.p<{
  size?: number;
  bold?: boolean;
  marginTop?: number;
  fontStyle?: string;
}>(({ size, bold, marginTop, fontStyle }) => ({
  fontSize: size ?? 14,
  fontWeight: bold ? 900 : 300,
  margin: marginTop ? `${marginTop}px 0px 0px 0px` : 0,
  fontStyle: fontStyle ?? 'none'
}));
