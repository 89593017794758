import styled from '@colligent/colligent-component-framework';
import { formatMoney } from '@colligent/colligent-component-framework/dist/formatters';
import React from 'react';
import Money from '../../model/Money';

export type Inline = { desktop: boolean; tablet: boolean; mobile: boolean } | boolean;

interface IFormattedMoneyProps {
  amount: Money;
  inline?: Inline;
}

const Container = styled.span<{ inline: Inline }>(({ theme, inline }) => {
  const isInlineOnDesktop = typeof inline === 'object' ? inline.desktop : inline;
  const isInlineOnTablet = typeof inline === 'object' ? inline.tablet : inline;
  const isInlineOnMobile = typeof inline === 'object' ? inline.mobile : inline;
  return {
    whiteSpace: 'nowrap',
    textAlign: 'right',
    width: isInlineOnDesktop ? 'auto' : '100%',
    display: isInlineOnDesktop ? 'inline' : 'inline-block',
    [theme.breakpoints.tabletAndLower]: {
      width: isInlineOnTablet ? 'auto' : '100%',
      display: isInlineOnTablet ? 'inline' : 'inline-block'
    },
    [theme.breakpoints.mobileAndLower]: {
      width: isInlineOnMobile ? 'auto' : '100%',
      display: isInlineOnMobile ? 'inline' : 'inline-block'
    }
  };
});

export const FormattedMoney: React.FC<IFormattedMoneyProps> = ({ amount, inline = false }) => {
  if (amount !== null && typeof amount.value === 'number' && typeof amount.currency === 'string') {
    return <Container inline={inline}>{formatMoney(amount.value, amount.currency.toUpperCase())}</Container>;
  } else {
    // TODO: Log error to Sentry here once that feature is implemented
    return null;
  }
};
