import React from 'react';
import profiles from './profiles';

type AppConfigProfile = 'fallback' | 'fi' | 'se';

type Locale = {
  label: string;
  code: string;
};

type Address = {
  street1: string;
  postalCode: string;
  city: string;
};

export type PaymentPlanOptions = {
  minimumAmount: number;
  totalDays: number;
};

export type SwishPaymentOptions = {
  minimumPaymentAmount: number;
  minimumEditableAmount: number;
  minimumDifferenceAmount: number;
};

export interface IMenuItem {
  label: string;
  url: string;
  translatable?: boolean;
}

interface OrganizationInfo {
  legalName: string;
  organizationNumber?: string;
  address: Address;
  phone: string;
  email: string;
  customerServiceUrl?: string;
}

interface I18n {
  defaultLocale: string;
  availableLocales: { [key: string]: Locale };
  dateFormat: string;
}

interface PaymentOptions {
  plusGiro: object;
  paymentPlan?: PaymentPlanOptions;
  swish?: SwishPaymentOptions;
}

interface GdprInfo {
  url: string;
}

interface AppConfig {
  i18n: I18n;
  topBarMenuItems: IMenuItem[];
  navBarMenuItems: IMenuItem[];
  topBarProfileMenuItems: IMenuItem[];
  footerMenuItems?: IMenuItem[];
  paymentOptions: PaymentOptions;
  gdprInfo?: GdprInfo;
  organizationInfo?: OrganizationInfo;
}

// process.env.REACT_APP_CONFIG_PROFILE is read from a local .env file
const appConfigProfile = (process?.env?.REACT_APP_CONFIG_PROFILE ?? 'fallback') as AppConfigProfile;
export const appConfig: AppConfig = profiles[appConfigProfile];

export const AppConfigContext = React.createContext(appConfig);

export const AppConfigContextProvider: React.FC = ({ children }) => {
  return <AppConfigContext.Provider value={appConfig}>{children}</AppConfigContext.Provider>;
};
