import axios, { AxiosError, AxiosInstance, AxiosRequestConfig, AxiosResponse } from 'axios';
import { useCallback, useState } from 'react';

export interface IResponse {
  data: any;
  pending: boolean;
  complete: boolean;
  error: boolean;
  status: number;
}

interface IAxiosRequestConfigWithInterceptor extends AxiosRequestConfig {
  data?: any; // TODO: Generic type instead?
  enableInterceptors?: boolean;
  requestCompletedCallback?: TRequestCallback;
}

type TRequestCallback = (...args: any) => {} | void;
type TDoRequest = (...args: any) => void;
type TUseEndpoint = [IResponse, TDoRequest];

export const shouldUseMockServer = (): boolean => {
  return !!process.env.REACT_APP_MOCK_API && process.env.REACT_APP_MOCK_API.toLowerCase() === 'yes';
};

const shouldCauseLogoutRedirect = (status: number) => {
  // Path names /login and /logout are whitelisted and should not cause a logout redirect
  const isWhitelistedPathName = window.location.pathname.match(/^\/(?:log(?:in|out))$/) !== null;

  return status === 401 && !isWhitelistedPathName;
};

const isInterceptorsEnabled = (config: IAxiosRequestConfigWithInterceptor) => {
  // Success / error interceptors will be enabled by default, unless explicitly already set by the request
  return config?.enableInterceptors ?? true;
};

const errorInterceptor = (error: AxiosError): Promise<never> => {
  if (isInterceptorsEnabled(error.config)) {
    const status = error.response && error.response.status;

    if (status === 401 || status === 403) {
      if (shouldCauseLogoutRedirect(status)) {
        window.location.assign('/logout');
      }
    }
  }

  return Promise.reject(error);
};

const successInterceptor = (response: AxiosResponse): AxiosResponse<any> => {
  // if (isInterceptorsEnabled(response.config)) {
  // @TODO: Need for handling response?
  // }
  return response;
};

// Single Axios instance with default config.
// Note! In 'production' we use the very same baseUrl
// as the API runs on, so a value of '' is fine here.
const axiosInstance: AxiosInstance = axios.create({
  baseURL: shouldUseMockServer() ? 'http://localhost:3001' : '',
  timeout: process.env.NODE_ENV === 'development' ? 180000 : 30000
});

// Registers success / error interceptors for response
axiosInstance.interceptors.response.use(
  response => successInterceptor(response),
  error => errorInterceptor(error)
);

export const useEndpoint = (requestConfig: IAxiosRequestConfigWithInterceptor, initalData?: any): TUseEndpoint => {
  const [response, setResponse] = useState({
    data: initalData,
    pending: false,
    complete: false,
    error: false,
    status: 0
  });
  const doRequest = useCallback(() => {
    // r === response, uses functional update of state with
    // argument "r" so we don't have to provide "response" as dependency
    setResponse(r => ({ ...r, pending: true, complete: false, error: false, status: 0 }));

    const doEndpointRequest = async (req: IAxiosRequestConfigWithInterceptor) => {
      try {
        const res = await axiosInstance.request(req);

        setResponse(r => ({ ...r, data: res.data, pending: false, complete: true, status: res.status }));

        if (req.requestCompletedCallback) {
          req.requestCompletedCallback();
        }
      } catch (error) {
        const status = error.response && error.response.status;
        const data = error.response && error.response.data;

        setResponse(r => ({ ...r, pending: false, error: true, complete: true, status, data }));
      }
    };

    doEndpointRequest(requestConfig);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [requestConfig.url, requestConfig.data]);

  return [response, doRequest];
};
