import { H1 } from '@colligent/colligent-component-framework/dist/common/typography';
import React from 'react';
import { Toast } from '../common/Toast';

interface IPageHeaderProps {
  title?: string;
}

export const PageHeader: React.FC<IPageHeaderProps> = ({ title, children }) => {
  return (
    <>
      <Toast />
      {title ? <H1>{title}</H1> : children}
    </>
  );
};
