/* eslint-disable max-len */
import React from 'react';
import uniqid from 'uniqid';

export const IconPen = () => (
  <svg
    height="15px"
    version="1.1"
    viewBox="0 0 12 15"
    width="12px"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
  >
    <g fill="none" fillRule="evenodd" id={uniqid('group-')} stroke="none" strokeWidth="1">
      <g fill="#FFFFFF" id={uniqid('group-')} transform="translate(-912.000000, -799.000000)">
        <g id={uniqid('group-')} transform="translate(812.000000, 785.000000)">
          <path
            d="M106.90947,16.7813763 L109.439363,18.687788 L103.73978,26.2513901 L101.209887,24.3449784 L106.90947,16.7813763 Z M109.635755,14.092361 L111.272745,15.3259215 C111.519313,15.5117243 111.527812,15.9163226 111.291728,16.2296173 L110.009322,17.9314277 C109.613584,17.633218 107.722011,16.2152301 107.479428,16.0250161 L108.761834,14.3232056 C108.997919,14.0099109 109.389186,13.9065583 109.635755,14.092361 Z M103.550807,26.9532881 L100,28.1176053 L100.426059,25.1343945 L103.550807,26.9532881 Z"
            id={uniqid('path-')}
          />
        </g>
      </g>
    </g>
  </svg>
);
