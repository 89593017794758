import { AppContainer, AppMain } from '@colligent/colligent-component-framework/dist/components';
import { useLocalStorage } from '@colligent/use-localstorage';
import { ILocaleContext, LocaleContext } from '@colligent/use-translation';
import React, { lazy, Suspense, useContext, useEffect, useReducer, useState } from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import 'reflect-metadata'; // needed for plainToClass object conversion
import { DebtorAppFooter, DebtorAppHeader, ProtectedRoute } from './components';
import { ErrorBoundary, ErrorBoundaryUpdateAvailable, MainSpinner } from './components/common';
import { initialPopupState, PopupContext, popupReducer } from './components/common/Popup';
import { PopupComponent } from './components/common/Popup/PopupComponent';
import { AppConfigContext, AppConfigContextProvider } from './config';
import { AuthenticatedContextProvider } from './hooks';

// lazy load screens to use code splitting
const AppRoot = lazy(() => import('./AppRoot'));
const Cases = lazy(() => import('./screens/cases/Cases'));
const Login = lazy(() => import('./screens/login/Login'));
const DebtorNotFound = lazy(() => import('./screens/debtor-not-found/DebtorNotFound'));
const Messages = lazy(() => import('./screens/messages/Messages'));
const UserDetails = lazy(() => import('./screens/user-details/UserDetails'));
const Logout = lazy(() => import('./screens/logout/Logout'));
const ErrorNotFound = lazy(() => import('./screens/errors/ErrorNotFound'));

const App: React.FC = () => {
  const appConfigContext = useContext(AppConfigContext);

  // bootstraps with activeLocale from localStorage, or fallback to defaultLocale from config
  const [activeLocale, setActiveLocale] = useLocalStorage('activeLocale', appConfigContext.i18n.defaultLocale);

  // locales available
  const [locales] = useState(appConfigContext.i18n.availableLocales);

  // defines LocaleContext based on appConfig values
  const localeContext: ILocaleContext = {
    activeLocale,
    locales,
    changeLocale: (locale: string) => {
      setActiveLocale(locale);
    }
  };

  useEffect(() => {
    // Updates the lang="en" attribute of the <html/> tag to reflect the current locale
    window.document.documentElement.lang = activeLocale;
  }, [activeLocale]);

  const [popup, dispatch] = useReducer(popupReducer, initialPopupState);

  return (
    <AppContainer>
      <Router>
        <AppConfigContextProvider>
          <AuthenticatedContextProvider>
            <LocaleContext.Provider value={localeContext}>
              <PopupContext.Provider value={{ popup, dispatch }}>
                <PopupComponent />
                <DebtorAppHeader />
                <AppMain>
                  <ErrorBoundary fallback={<ErrorBoundaryUpdateAvailable />}>
                    <Suspense fallback={<MainSpinner />}>
                      <Switch>
                        <Route component={AppRoot} exact={true} path="/" />
                        <ProtectedRoute component={Cases} path="/cases" />
                        <ProtectedRoute component={Messages} path="/messages" />
                        <ProtectedRoute component={UserDetails} path="/user" />
                        <ProtectedRoute component={Logout} path="/logout" />
                        <Route component={DebtorNotFound} path="/debtor-not-found" />
                        <Route component={Login} path="/login" />
                        <Route component={ErrorNotFound} path="*" />
                      </Switch>
                    </Suspense>
                  </ErrorBoundary>
                </AppMain>
                <DebtorAppFooter />
              </PopupContext.Provider>
            </LocaleContext.Provider>
          </AuthenticatedContextProvider>
        </AppConfigContextProvider>
      </Router>
    </AppContainer>
  );
};

export default App;
