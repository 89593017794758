import { LocaleContext } from '@colligent/use-translation';
import { useContext, useMemo } from 'react';

interface ILocalizedCountry {
  key: string;
  label: string;
}

interface ICountriesByLanguage {
  [key: string]: ILocalizedCountry[];
}

const countries: ICountriesByLanguage = {
  en: [
    {
      key: 'DE',
      label: 'Germany'
    },
    {
      key: 'DK',
      label: 'Denmark'
    },
    {
      key: 'EE',
      label: 'Estonia'
    },
    {
      key: 'ES',
      label: 'Spain'
    },
    {
      key: 'FI',
      label: 'Finland'
    },
    {
      key: 'GB',
      label: 'Great Britain'
    },
    {
      key: 'IS',
      label: 'Iceland'
    },
    {
      key: 'NO',
      label: 'Norway'
    },
    {
      key: 'SE',
      label: 'Sweden'
    }
  ],

  fi: [
    {
      key: 'DE',
      label: 'Saksa'
    },
    {
      key: 'DK',
      label: 'Tanska'
    },
    {
      key: 'EE',
      label: 'Viro'
    },
    {
      key: 'ES',
      label: 'Espanja'
    },
    {
      key: 'FI',
      label: 'Suomi'
    },
    {
      key: 'GB',
      label: 'Iso-Britannia'
    },
    {
      key: 'IS',
      label: 'Islanti'
    },
    {
      key: 'NO',
      label: 'Norja'
    },
    {
      key: 'SE',
      label: 'Ruotsi'
    }
  ],

  sv: [
    {
      key: 'DE',
      label: 'Tyskland'
    },
    {
      key: 'DK',
      label: 'Danmark'
    },
    {
      key: 'EE',
      label: 'Estland'
    },
    {
      key: 'ES',
      label: 'Spanien'
    },
    {
      key: 'FI',
      label: 'Finland'
    },
    {
      key: 'GB',
      label: 'Storbritannien'
    },
    {
      key: 'IS',
      label: 'Island'
    },
    {
      key: 'NO',
      label: 'Norge'
    },
    {
      key: 'SE',
      label: 'Sverige'
    }
  ]
};

export const useLocalizedCountries = () => {
  const { activeLocale } = useContext(LocaleContext);

  const countryCodes = useMemo(() => {
    const codes: string[] = [];

    countries.en.forEach(country => {
      codes.push(country.key);
    });

    return codes;
  }, []);

  const countryList = useMemo(() => {
    return countries[activeLocale].sort((a: ILocalizedCountry, b: ILocalizedCountry) => {
      return a.label > b.label ? 1 : -1;
    }) as ILocalizedCountry[];
  }, [activeLocale]);

  return {
    countryCodes,
    countryList
  };
};
