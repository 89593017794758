import React, { useContext } from 'react';
import { RouteProps, useHistory, useRouteMatch } from 'react-router-dom';
import { AuthenticatedContext } from '../../hooks';

interface IProtectedRoute extends RouteProps {
  component: React.ComponentType;
  rest?: any;
}

export const ProtectedRoute: React.FC<IProtectedRoute> = ({ component: Component, ...rest }) => {
  const { authenticated } = useContext(AuthenticatedContext);
  const match = useRouteMatch();
  const history = useHistory();
  console.log('ProtectedRoute: match', match);
  if (!authenticated) {
    history.push(`/login`);

    return null;
  }

  return <Component path="/*" {...rest} />;
};
