import { createEncodedBgSvg } from '@colligent/colligent-component-framework/dist/formatters';
import React from 'react';
import ReactDomServer from 'react-dom/server';
import { IconArrow } from '../IconArrow';
import { IconCheckMark } from '../IconCheckMark';
import { IconExclamationMark } from '../IconExclamationMark';
import { IconToast } from '../IconToast';

export const encodedIconArrow = (color: string) =>
  createEncodedBgSvg(ReactDomServer.renderToString(<IconArrow color={color} />));

export const encodedIconCheckMark = createEncodedBgSvg(ReactDomServer.renderToString(<IconCheckMark />));

export const encodedIconExclamationMark = createEncodedBgSvg(ReactDomServer.renderToString(<IconExclamationMark />));

export const encodedIconToast = createEncodedBgSvg(ReactDomServer.renderToString(<IconToast />));
