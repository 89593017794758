import { ILocaleResource } from '@colligent/use-translation';

export const resources: ILocaleResource = {
  en: {
    applicationErrorTitle: 'Oops!',
    applicationErrorMessage: `An unexpected error occurred. It's not your fault, it's ours. Details about the error has been logged and we have been notified. You may try starting over.`,
    applicationErrorButtonLabel: 'Start over',
    applicationUpdateAvailableTitle: 'Update available',
    applicationUpdateAvailableMessage:
      'An updated version of My Pages is available. You will be logged out while we update to the new version for you.',
    applicationUpdateAvailableButtonLabel: 'Update now'
  },
  fi: {
    applicationErrorTitle: 'Oho!',
    applicationErrorMessage: `Jotain meni pieleen. Se ei ole sinun syytäsi, se on meidän. Vika on ilmoitettu eteenpäin ja tutkimme asiaa. Voit yrittää uudelleen.`,
    applicationErrorButtonLabel: 'Aloita alusta',
    applicationUpdateAvailableTitle: 'Päivitys saatavilla',
    applicationUpdateAvailableMessage:
      'OmaRopoCapital- sivusta on nyt saatavilla päivitetty versio. Sinut kirjataan ulos sivustolta päivityksen ajaksi.',
    applicationUpdateAvailableButtonLabel: 'Päivitä nyt'
  },
  sv: {
    applicationErrorTitle: 'Hoppsan!',
    applicationErrorMessage: `Ett oväntat fel uppstod. Det är inte ditt fel, det är vårt. Detaljer om felet har loggats och vi har blivit underrättade. Du kan prova med att börja om igen.`,
    applicationErrorButtonLabel: 'Börja om',
    applicationUpdateAvailableTitle: 'Uppdatering tillgänglig',
    applicationUpdateAvailableMessage:
      'En uppdaterad version av Mina sidor är tillgänglig. Du kommer att loggas ut medan vi uppdaterar till den nya versionen åt dig.',
    applicationUpdateAvailableButtonLabel: 'Uppdatera nu'
  }
};
