import styled from '@colligent/colligent-component-framework';
import { useTranslation } from '@colligent/use-translation';
import React, { useContext } from 'react';
import { AppConfigContext, IMenuItem } from '../config';
import { resources } from './resources.i18n';

const Footer = styled.section<{}>(({ theme }) => ({
  flexGrow: 5,
  display: 'flex',
  alignItems: 'center',
  borderLeft: `1px solid ${theme.colors.white}`,
  minHeight: 200,
  margin: '20px 20px 20px 0',
  paddingLeft: 30,

  [theme.breakpoints.mobileAndLower]: {
    borderLeft: 'none',
    borderTop: `1px solid ${theme.colors.white}`,
    margin: '20px 20px 30px 20px',
    minHeight: 'unset',
    paddingLeft: 0
  },

  a: {
    borderBottom: `1px solid ${theme.colors.white}`,
    textDecoration: 'none'
  },

  '& ul': {
    listStyle: 'none',
    margin: '0',
    paddingLeft: 0,

    [theme.breakpoints.mobileAndLower]: {
      marginTop: 30
    },

    '& li': {
      marginBottom: 10,

      '&:last-of-type': {
        marginBottom: 0
      }
    }
  }
}));

const FooterMenuListItems: React.FC<IMenuItem> = ({ label, url }) => {
  const t = useTranslation(resources);

  return (
    <li>
      <a href={url}>{t(label)}</a>
    </li>
  );
};

export const FooterMenu: React.FC = () => {
  const { footerMenuItems } = useContext(AppConfigContext);

  return (
    <Footer>
      {footerMenuItems ? (
        <ul>
          {footerMenuItems.map((item: IMenuItem) => (
            <FooterMenuListItems key={item.label} label={item.label} url={item.url} />
          ))}
        </ul>
      ) : null}
    </Footer>
  );
};
