import { DebtorResponse } from '../models/api';

export default class DebtorData implements DebtorResponse {
  public personalNumber: string = '';

  public debtorNumber: number = 0;

  public firstName: string = '';

  public lastName: string = '';

  public coAddress: string = '';

  public address: string = '';

  public zipCode: string = '';

  public city: string = '';

  public country: string = '';

  public phoneNumber: string = '';

  public email: string = '';

  public debtorType: number = 0;

  public contactViaSms: boolean = false;

  public contactViaEmail: boolean = false;

  public fullName = () => this.firstName + (this.lastName ? ` ${this.lastName}` : '');
}
