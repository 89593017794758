import styled from '@colligent/colligent-component-framework';
import {
  ApplicationMessage,
  IApplicationMessage,
  IApplicationMessageButton
} from '@colligent/colligent-component-framework/dist/common/components/ApplicationMessage';
import { useTranslation } from '@colligent/use-translation';
import * as Sentry from '@sentry/browser';
import React, { ReactNode } from 'react';
import { PageContainer } from '../../Page';
import { resources } from './resources.i18n';

interface IErrorProps {
  fallback?: string | ReactNode;
  logToSentry?: boolean;
}

interface IErrorState {
  hasError: boolean;
}

export class ErrorBoundary extends React.Component<IErrorProps, IErrorState> {
  static defaultProps = {
    logToSentry: true
  };

  static getDerivedStateFromError() {
    return { hasError: true };
  }

  state = {
    hasError: false
  };

  componentDidCatch(error: Error | null, errorInfo: object) {
    const logToSentry = this.props;

    // if (isRedirect(error)) {
    if (logToSentry) {
      this.logToSentry(error, errorInfo);
    }

    // TODO: @Pontus: isREdirect(error) solution may be needed (compare to previous version of the file), investigate...
    throw error;
  }

  logToSentry = (error: Error | null, errorInfo: object) => {
    Sentry.withScope(scope => {
      scope.setTag('module', 'ErrorBoundary');
      scope.setFingerprint(['ErrorBoundary']);
      scope.setExtras({ extras: errorInfo });

      Sentry.captureMessage(`ErrorBoundary: ${error}.`);
    });
  };

  render() {
    const { children, fallback } = this.props;
    const { hasError } = this.state;

    if (hasError) {
      return fallback ?? <ErrorBoundaryGenericError />;
    }

    return children;
  }
}

const Container = styled('div')<{}>(() => ({
  marginTop: 60,
  marginBottom: 60
}));

const ErrorBoundaryMessage: React.FC<IApplicationMessage> = ({ button, message, messageType, title }) => {
  return (
    <PageContainer>
      <Container>
        <ApplicationMessage button={button} message={message} messageType={messageType} title={title} />
      </Container>
    </PageContainer>
  );
};

export const ErrorBoundaryUpdateAvailable = () => {
  const t = useTranslation(resources);

  const button: IApplicationMessageButton = {
    label: t('applicationUpdateAvailableButtonLabel'),
    onClick: () => {
      // Unable to use reach-router for navigation if we want to reset the app completely.
      window.location.assign('/api/logout');
    }
  };

  return (
    <ErrorBoundaryMessage
      button={button}
      message={t('applicationUpdateAvailableMessage')}
      messageType="info"
      title={t('applicationUpdateAvailableTitle')}
    />
  );
};

const ErrorBoundaryGenericError: React.FC = () => {
  const t = useTranslation(resources);

  const button: IApplicationMessageButton = {
    label: t('applicationErrorButtonLabel'),
    onClick: () => {
      // Unable to use reach-router for navigation if we want to reset the app completely.
      window.location.assign('/');
    }
  };

  return (
    <ErrorBoundaryMessage
      button={button}
      message={t('applicationErrorMessage')}
      messageType="error"
      title={t('applicationErrorTitle')}
    />
  );
};
