export default {
  i18n: {
    defaultLocale: 'en',
    availableLocales: {
      en: {
        label: 'English',
        code: 'en'
      }
    },
    dateFormat: 'yyyy-MM-dd'
  },
  topBarMenuItems: [
    {
      label: 'My Pages',
      url: '/'
    }
  ],
  navBarMenuItems: [
    {
      label: 'Cases',
      url: '/cases'
    },
    {
      label: 'Messages',
      url: '/messages'
    },
    {
      label: 'My Details',
      url: '/user'
    }
  ],
  topBarProfileMenuItems: [
    {
      label: 'My Details',
      url: '/user'
    },
    {
      label: 'Logout',
      url: '/logout'
    }
  ],
  paymentOptions: {
    plusGiro: {}
  }
};
