/* eslint-disable max-len */
import React from 'react';
import uniqid from 'uniqid';

interface ICheckMark {
  backgroundColor?: string;
  foregroundColor?: string;
}

export const IconCheckMark: React.FC<ICheckMark> = ({ backgroundColor = '#99D161', foregroundColor = '#FFF' }) => {
  return (
    <svg
      height="22px"
      version="1.1"
      viewBox="0 0 22 22"
      width="22px"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
    >
      <g fill="none" fillRule="evenodd" id={uniqid('group-')} stroke="none">
        <g id={uniqid('group-')}>
          <g id={uniqid('group-')}>
            <g id={uniqid('group-')}>
              <path
                d="M0,10 C0,15.5333333 4.46666667,20 10,20 C15.5333333,20 20,15.5333333 20,10 C20,4.46666667 15.5333333,0 10,0 C4.46666667,0 0,4.46666667 0,10 Z"
                fill={backgroundColor}
                id={uniqid('path-')}
                stroke={backgroundColor}
              />
              <polygon
                fill={foregroundColor}
                id={uniqid('plygon-')}
                points="6.25870015 13.284233 4 10.9659903 5.64415767 9.27849026 7.90285781 11.596733 13.3558423 6 15 7.6875 7.87531678 15 6.23115912 13.3125"
              />
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
};
