import { Popup } from '@colligent/colligent-component-framework/dist/common/components/Popup';
import React, { useContext } from 'react';
import { PopupAction, PopupContext } from '.';

export const PopupComponent: React.FC<{}> = () => {
  const { popup, dispatch } = useContext(PopupContext);
  const { buttonText, isError, isOpen, message, title } = popup;

  const handleOnClose = () => {
    dispatch({ type: PopupAction.SetIsOpen, payload: false });
    dispatch({ type: PopupAction.SetIsError, payload: false });
  };

  return (
    <>
      {isOpen ? (
        <Popup
          buttonText={buttonText}
          isError={isError}
          isOpen={isOpen}
          message={message}
          title={title}
          onClose={handleOnClose}
        />
      ) : (
        <></>
      )}
    </>
  );
};
