/* eslint-disable no-unused-vars */
/* eslint-disable @typescript-eslint/no-unused-vars */
import { useLocalStorage } from '@colligent/use-localstorage';
import React from 'react';
import { useDebtor } from '.';
import DebtorData from '../model/DebtorData';
import { IResponse } from './use-endpoint';

type IUseAuthenticatedHook = () => [boolean, React.Dispatch<React.SetStateAction<boolean>>];

interface IAuthenticatedContext {
  authenticated: boolean;
  debtor: DebtorData;
  debtorResponse: IResponse;
  setDebtor(debtor: DebtorData): void;
  setAuthenticated(isAuthenticated: boolean): void;
}

export const useAuthenticated: IUseAuthenticatedHook = () => {
  const [authenticated, setAuthenticated] = useLocalStorage('authenticated', false);

  return [authenticated, setAuthenticated];
};

const INITIAL_AUTHENTICATED_CONTEXT: IAuthenticatedContext = {
  authenticated: false,
  debtor: new DebtorData(),
  debtorResponse: { data: {}, pending: false, complete: false, error: false, status: 0 },
  setDebtor: (debtor: DebtorData) => {},
  setAuthenticated: (isAuthenticated: boolean) => {}
};

export const AuthenticatedContext = React.createContext<IAuthenticatedContext>(INITIAL_AUTHENTICATED_CONTEXT);

export const AuthenticatedContextProvider: React.FC = ({ children }) => {
  const [authenticated, setAuthenticated] = useAuthenticated();
  const { debtor, setDebtor, debtorResponse } = useDebtor();

  const authenticatedContext: IAuthenticatedContext = {
    authenticated,
    debtor,
    setDebtor,
    setAuthenticated,
    debtorResponse
  };

  return <AuthenticatedContext.Provider value={authenticatedContext}>{children}</AuthenticatedContext.Provider>;
};
