import styled from '@colligent/colligent-component-framework';
import { Logo, NavBar, NavBarMenu } from '@colligent/colligent-component-framework/dist/components/AppHeader/NavBar';
import { TopBar, TopBarProfile } from '@colligent/colligent-component-framework/dist/components/AppHeader/TopBar';
import { TopBarLogout } from '@colligent/colligent-component-framework/dist/components/AppHeader/TopBar/TopBarLogout';
import { TopBarMenu } from '@colligent/colligent-component-framework/dist/components/AppHeader/TopBar/TopBarMenu';
import { Cover } from '@colligent/colligent-component-framework/dist/components/Cover';
import { LocaleContext, useTranslation } from '@colligent/use-translation';
import React, { useCallback, useContext, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { AppConfigContext, IMenuItem } from '../config';
import { AuthenticatedContext } from '../hooks';
import { MenuLanguages } from './MenuLanguages';
import { resources } from './resources.i18n';
import ropoLogo from '../logos/Ropo_logo3_RGB_black_177x36.png';
import DebtorData from '../model/DebtorData';

const Container = styled.header({});

const useMemoizedMenuItems = (menuItems: IMenuItem[]) => {
  const t = useTranslation(resources);
  const translateLabels = useCallback(
    (items: IMenuItem[]) => {
      return items.map(item => ({
        ...item,
        label: typeof item.translatable === 'undefined' || item.translatable ? t(item.label) : item.label
      }));
    },
    [t]
  );

  return translateLabels(menuItems);
};

const logoElement = <img alt="Ropo" height={36} src={ropoLogo} style={{ verticalAlign: 'bottom' }} width={98} />;

export const DebtorAppHeader: React.FC = () => {
  const { locales } = useContext(LocaleContext);
  const { authenticated, debtor, setAuthenticated, setDebtor } = useContext(AuthenticatedContext);
  const { topBarMenuItems, navBarMenuItems, topBarProfileMenuItems } = useContext(AppConfigContext);
  const memoizedTopBarMenuItems = useMemoizedMenuItems(topBarMenuItems);
  const memoizedTopBarProfileMenuItems = useMemoizedMenuItems(topBarProfileMenuItems);
  const memoizedNavBarMenuItems = useMemoizedMenuItems(navBarMenuItems);
  const t = useTranslation(resources);
  const [isCoverOpen, setIsCoverOpen] = useState(false);

  const location = useLocation();

  const handleLogout = () => {
    // An explicit logout action by the user.
    // Therefore its perfectly safe to deauthentiticate the user here.
    setAuthenticated(false);
    setDebtor(new DebtorData());
    window.location.href = '/api/logout';
  };

  // Determine if the logout button should be shown based on authenticated state and current URI
  const shouldShowLogoutButton = authenticated || location.pathname === '/debtor-not-found';

  return (
    <Container>
      <TopBar>
        <TopBarMenu menuItems={memoizedTopBarMenuItems} setCoverOpen={setIsCoverOpen} />
        <MenuLanguages menuItems={locales} />
        {authenticated && debtor && (
          <>
            <TopBarProfile
              desktopLink="/user"
              label={t('Logged in as')}
              menuItems={memoizedTopBarProfileMenuItems}
              name={debtor.fullName()}
              setCoverOpen={setIsCoverOpen}
              subLabel={t('ProfileMobileLabel')}
            />
          </>
        )}
        {/* Conditionally render the logout button */}
        {shouldShowLogoutButton && <TopBarLogout label={t('Logout')} onClick={handleLogout} />}
        {/* <Link to="/api/logout">
            <TopBarLogout label={t('Logout')} />
          </Link> */}
      </TopBar>
      <NavBar>
        <Logo logo={logoElement} />
        {authenticated && (
          <NavBarMenu
            closedLabel={t('MenuClosedLabel')}
            menuItems={memoizedNavBarMenuItems}
            openLabel={t('MenuOpenLabel')}
            setCoverOpen={setIsCoverOpen}
          />
        )}
      </NavBar>
      <Cover isCoverOpen={isCoverOpen} />
    </Container>
  );
};
