export default {
  i18n: {
    defaultLocale: 'sv',
    availableLocales: {
      en: {
        label: 'English',
        code: 'en'
      },
      sv: {
        label: 'Svenska',
        code: 'sv'
      }
    },
    dateFormat: 'yyyy-MM-dd'
  },
  topBarMenuItems: [
    {
      label: 'My Pages',
      url: '/'
    },
    {
      label: 'ropo.se',
      url: 'https://ropo.se',
      translatable: false
    }
  ],
  navBarMenuItems: [
    {
      label: 'Cases',
      url: '/cases'
    },
    {
      label: 'Messages',
      url: '/messages'
    },
    {
      label: 'My Details',
      url: '/user'
    }
  ],
  topBarProfileMenuItems: [
    {
      label: 'My Details',
      url: '/user'
    },
    {
      label: 'Logout',
      url: '/logout'
    }
  ],
  footerMenuItems: [
    {
      label: 'FAQ',
      url: 'https://www.ropo.se/inkassokrav/fragor-och-svar/'
    },
    {
      label: 'Complaints',
      url: 'https://www.ropo.se/klagomal/'
    },
    {
      label: 'PersonalData',
      url: 'https://www.ropo.se/dataskydd-inkasso-och-fakturakunder/'
    },
    {
      label: 'Cookies',
      url: 'https://www.ropo.se/cookies/'
    }
  ],
  paymentOptions: {
    plusGiro: {},
    paymentPlan: {
      minimumAmount: 250,
      totalDays: 31
    },
    swish: {
      minimumPaymentAmount: 180,
      minimumEditableAmount: 230,
      minimumDifferenceAmount: 50
    }
  },
  gdprInfo: {
    url: 'https://www.ropo.se/dataskydd-inkasso-och-fakturakunder/'
  },
  organizationInfo: {
    legalName: 'Ropo AB',
    organizationNumber: '556527-5418',
    address: {
      street1: 'Box 114 82',
      postalCode: '404 30',
      city: 'Göteborg'
    },
    phone: '010 - 174 01 90',
    email: 'info.sweden@ropo.com',
    customerServiceUrl: 'https://www.ropo.se/kundservice/'
  }
};
