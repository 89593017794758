/* eslint-disable max-len */
import React from 'react';
import uniqid from 'uniqid';

export const IconToast: React.FC = () => {
  return (
    <svg height="25px" version="1.1" viewBox="0 0 8 25" width="8px" xmlns="http://www.w3.org/2000/svg">
      <g fill="none" fillRule="evenodd" id={uniqid('group-')} stroke="none" strokeWidth="1">
        <g fill="#FFFFFF" id={uniqid('group-')} transform="translate(-2258.000000, -1645.000000)">
          <g id={uniqid('group-')} transform="translate(2258.000000, 1645.000000)">
            <path
              d="M3.95620448,14.8639456 C2.66801949,14.8639456 1.57301153,13.9137615 1.37921441,12.6277839 L0.0364197211,3.71741467 C0.0121745472,3.55653121 0,3.39402814 0,3.23129257 C0,1.44669896 1.43268882,0 3.20000005,0 L4.71240891,0 C4.87356852,0 5.03449788,0.012293601 5.19382331,0.0367758663 C6.94102048,0.305253692 8.14186707,1.95313182 7.87598924,3.71741467 L6.53319455,12.6277839 C6.33939743,13.9137615 5.24438947,14.8639456 3.95620448,14.8639456 Z"
              id={uniqid('path-')}
            />
            <ellipse
              cx="4.2"
              cy="21.2312925"
              id={uniqid('ellipse-')}
              rx="3.2"
              ry="3.23129252"
              transform="translate(4.200000, 21.231293) scale(1, -1) translate(-4.200000, -21.231293) "
            />
          </g>
        </g>
      </g>
    </svg>
  );
};
