import * as Sentry from '@sentry/browser';

/**
 * Sentry.io configuration and initialization.
 *
 * We use Sentry.io mainly for production environments. Events sent from localhost are blocked via
 * filters in Sentry.io but we block it here via process.env.NODE_ENV also to prevent unwanted
 * error spamming in the browser console.
 *
 * Variables "process.env.REACT_APP_SENTRY_DSN" and "process.env.REACT_APP_SENTRY_RELEASE"
 * are read from .env files local to the app root of the application. You may have to
 * create the .env file yourself.
 */

class SentryConfigError extends Error {
  constructor(configKey?: string) {
    super(`[Sentry.io] No "${configKey}" configured. You should add one.`);

    Object.setPrototypeOf(this, new.target.prototype);
    this.name = SentryConfigError.name;
  }
}

// Only initialize Sentry.io in production mode
if (process.env.NODE_ENV === 'production') {
  try {
    if (!process.env.REACT_APP_SENTRY_DSN) {
      throw new SentryConfigError('process.env.REACT_APP_SENTRY_DSN');
    }
    if (!process.env.REACT_APP_SENTRY_RELEASE) {
      throw new SentryConfigError('process.env.REACT_APP_SENTRY_RELEASE');
    }

    Sentry.init({
      dsn: process.env.REACT_APP_SENTRY_DSN,
      release: process.env.REACT_APP_SENTRY_RELEASE
    });
  } catch (error) {
    // eslint-disable-next-line no-console
    console.warn(error);
  }
}
