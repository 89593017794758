import { plainToClass } from 'class-transformer';
import React, { useEffect, useState } from 'react';
import { useEndpoint } from '.';
import DebtorData from '../model/DebtorData';
import { IResponse } from './use-endpoint';

type TUseDebtorHook = () => {
  debtor: DebtorData;
  setDebtor: React.Dispatch<React.SetStateAction<DebtorData>>;
  debtorResponse: IResponse;
};

export const useDebtor: TUseDebtorHook = () => {
  const newDebtorData = new DebtorData();
  const [debtor, setDebtor] = useState();
  const [debtorResponse, doRequest] = useEndpoint(
    {
      method: 'GET',
      url: '/api/debtor'
    },
    newDebtorData
  );
  const { data, pending, complete } = debtorResponse;

  useEffect(() => {
    if (!pending && !complete) {
      doRequest();
    }
  }, [doRequest, complete, pending]);

  useEffect(() => {
    if (complete) {
      setDebtor(plainToClass(DebtorData, data as DebtorData));
    }
  }, [complete, data]);

  return { debtor, setDebtor, debtorResponse };
};
