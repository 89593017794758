import styled from '@colligent/colligent-component-framework';
import React, { useEffect, useMemo, useState } from 'react';
import { useAuthenticated, useEndpoint } from '../../../hooks';
import { encodedIconToast } from '../../../icons';

type Level = 'info' | 'warning' | 'error';

interface IToastIcon {
  level: Level;
}

interface IToast {
  enableToast: boolean;
  message: string;
  level: Level;
  requireAuthentication: boolean;
}

const Container = styled.div<{}>(({ theme }) => ({
  backgroundColor: theme.colors.gray10,
  display: 'flex',
  borderRadius: 4,
  padding: 20,
  marginTop: 30
}));

const ToastMessage = styled.p<{}>(({ theme }) => ({
  font: theme.fonts.desktop.small,
  margin: 0,
  marginLeft: 20,
  alignSelf: 'center',
  maxWidth: '75%',
  textAlign: 'left',

  [theme.breakpoints.mobileAndLower]: {
    font: theme.fonts.mobile.small
  }
}));

const ToastIcon = styled.div<IToastIcon>(({ level, theme }) => ({
  backgroundColor: theme.colors[level],
  backgroundImage: encodedIconToast,
  backgroundRepeat: 'no-repeat',
  backgroundPosition: 'center',
  backgroundSize: '8px 25px',
  borderRadius: 4,
  height: 60,
  width: 60,
  alignSelf: 'center',

  [theme.breakpoints.mobileAndLower]: {
    alignSelf: 'stretch',
    height: 48,
    width: 48,
    backgroundSize: '30% 30%'
  }
}));

const TOAST_INITIAL_STATE: IToast = {
  enableToast: false,
  message: '',
  level: 'info',
  requireAuthentication: false
};

export const Toast = () => {
  const [authenticated] = useAuthenticated();
  const [{ enableToast, message, level, requireAuthentication }, setToastInformation] = useState(TOAST_INITIAL_STATE);
  const [initialLoad, setInitialLoad] = useState(false);

  const [{ complete, data }, getToast] = useEndpoint({
    method: 'GET',
    url: `/api/toast`
  });

  const shouldShowToast = useMemo(() => enableToast && (!requireAuthentication || authenticated), [
    enableToast,
    requireAuthentication,
    authenticated
  ]);

  useEffect(() => {
    if (!initialLoad) {
      getToast();
      setInitialLoad(true);
    }
  }, [initialLoad, getToast]);

  useEffect(() => {
    if (complete && data) {
      setToastInformation({
        enableToast: data.enableToast,
        message: data.message,
        level: data.level,
        requireAuthentication: data.requireAuthentication
      });
    }
  }, [complete, data]);

  return shouldShowToast ? (
    <Container>
      <ToastIcon level={level} />
      <ToastMessage>{message}</ToastMessage>
    </Container>
  ) : null;
};
