export default {
  i18n: {
    defaultLocale: 'fi',
    availableLocales: {
      en: {
        label: 'English',
        code: 'en'
      },
      sv: {
        label: 'Svenska',
        code: 'sv'
      },
      fi: {
        label: 'Suomi',
        code: 'fi'
      }
    },
    dateFormat: 'd.M.yyyy'
  },
  topBarMenuItems: [
    {
      label: 'My Pages',
      url: '/'
    },
    {
      label: 'ropocapital.fi',
      url: 'https://ropocapital.fi',
      translatable: false
    }
  ],
  navBarMenuItems: [
    {
      label: 'Cases',
      url: '/cases'
    },
    {
      label: 'Messages',
      url: '/messages'
    },
    {
      label: 'My Details',
      url: '/user'
    }
  ],
  topBarProfileMenuItems: [
    {
      label: 'My Details',
      url: '/user'
    },
    {
      label: 'Logout',
      url: '/logout'
    }
  ],
  footerMenuItems: [
    {
      label: 'FAQ',
      url: 'https://www.ropocapital.fi/maksuvaatimus/usein-kysyttya/'
    },
    {
      label: 'Complaints',
      url: 'https://www.ropocapital.fi/henkilotietojen-kasittely-ja-evasteet/reklamaatiot/'
    },
    {
      label: 'PersonalData',
      url: 'https://www.ropocapital.fi/henkilotietojen-kasittely-ja-evasteet/'
    },
    {
      label: 'Cookies',
      url: 'https://www.ropocapital.fi/henkilotietojen-kasittely-ja-evasteet/evasteet/'
    }
  ],
  paymentOptions: {
    plusGiro: {},
    paymentPlan: {
      minimumAmount: 25,
      totalDays: 31
    }
  },
  gdprInfo: {
    url: 'https://www.ropocapital.fi/henkilotietojen-kasittely-ja-evasteet/'
  },
  organizationInfo: {
    legalName: 'Ropo AB',
    address: {
      street1: 'PL 79',
      postalCode: '00101',
      city: 'Helsinki'
    },
    phone: '+358 9 3158 9933',
    email: 'asiakaspalvelu@ropocapital.fi',
    customerServiceUrl: 'https://www.ropocapital.fi/asiakaspalvelu/'
  }
};
