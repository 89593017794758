/* eslint-disable max-len */
import React from 'react';
import uniqid from 'uniqid';

export const IconExclamationMark: React.FC = () => {
  return (
    <svg height="20" viewBox="0 0 20 20" width="20" xmlns="http://www.w3.org/2000/svg">
      <g fill="none" fillRule="evenodd" id={uniqid('group-')}>
        <circle cx="10" cy="16" fill="#FFF" id={uniqid('circle-')} r="1.533" />
        <path
          d="M10 0C4.467 0 0 4.467 0 10s4.467 10 10 10 10-4.467 10-10S15.533 0 10 0z"
          fill="#EF7D00"
          id={uniqid('path-')}
        />
        <circle cx="10" cy="14.6" fill="#FFF" id={uniqid('circle-')} r="1.533" />
        <path
          d="M11.733 5.867L11 11a.733.733 0 0 1-.4.667c-.2.133-.4.2-.6.2h-.133c-.467-.067-.8-.467-.867-.934l-.733-5.066c-.134-1 .533-1.867 1.533-2 .933-.134 1.8.6 1.933 1.533v.467z"
          fill="#FFF"
          id={uniqid('path-')}
        />
      </g>
    </svg>
  );
};
